import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ReviewsBlock = () => {
  const [lastReviews, setLastReviews] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get("https://api.steam-rent.com/getReviews")
      .then((resp) => setLastReviews(resp.data));
    console.log(lastReviews);
  }, []);

  return (
    <div onClick={() => console.log(lastReviews)} className="short-recomdendtions__container">
      {lastReviews.map((r) => (
        <div className="short-recomendation__item">
          <p
            onClick={() => navigate(`/users?email=${r.email}`)}
            style={{ cursor: "pointer" }}
          >
            {r.email}
          </p>

          <strong>
            {Array.from({ length: r.rate }).map((s) => (
              <img src={require("../assets/img/fullStar.png")} alt="" />
            ))}
          </strong>

          <div className="review-text">
            <p>{r.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewsBlock;
